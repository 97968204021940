import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'

import data from '../../data/_data'
import PflegenFusszeile from '../Fusszeile'
import PflegenKopfzeile from '../Suche_Kopfzeile'
import { arrayDefaultFilter, checkInvalidation, checkReadOnly, completeCompetenceAfterUpdate, dict2options, fieldData2options, flattenObject2Array, object2options, objectList2options, optionsFilter, searchObject } from '../_helpers/functions'
import { sendDeleteFileRequest as sendDeleteConsultantFileRequest, sendFileDownload as sendConsultantFileDownload, sendUpdateConsultantRequest, sendFileUpload as sendConsultantFileUpload, sendCreateFileRequest as sendCreateConsultantFileRequest } from '../../redux/consultants'
import { sendGetConsultantprojectRequest, sendGetConsultantprojectListRequest, sendUpdateConsultantprojectRequest, sendDeleteFileRequest as sendDeleteConsultantprojectFileRequest, sendFileDownload as sendConsultantprojectFileDownload, sendCreateFileRequest as sendCreateConsultantprojectFileRequest } from '../../redux/consultantprojects'
import businessobject_data from '../../data/businessobject_data'
import { useHistory } from 'react-router';
import { defaultEncoder, optionsDataByTypeInit, getOptionsInit, objectList2AttrList, validateValues, equals, enum2label, somethingChanges } from './../_helpers/functions';
import { sendGetConsultantRequest } from './../../redux/consultants';
import { InputByType, ObjectFKInput, TableInput, InLineInput, RowInput, DropdownInput, FileInput, TextInput } from './../_helpers/InnerContent';
import { PermissionForbidden } from './../_helpers/ReadPermission';
import { sendCreatePMCompetenceRequest } from '../../redux/competences'
import { sendCreatePMQualificationRequest } from './../../redux/competences';
import message_data from './../../data/message_data';
import { setSuccess } from '../../redux/businessobjects'
import PflegenKopfzeile_Gefuehrt from './PflegenKopfzeile_Gefuehrt'

/**
 * Haupt-Komponente für die Seiten "Beraterprofil Pflegen".
 * Geführtes aktualisieren des Beraters und seiner Beraterprojekte
 * 
 * @author DHR
 * 
 * @returns {HTML} Seite zum Suchen der Geschaeftsobjekte (inkl. Kopf- und Fußzeile) 
 * @see PflegenKopfzeile
 * @see PflegenFusszeile
 */
function Pflegen_Gefuehrt({role, username, consultantList, updatedId, setfurtherSuccess, consultantprojectList, getConsultantprojectList, fkLists, errorMsg, successMsg, pmQualificationSuccess, pmCompetenceSuccess, businessObject=data.businessObject.gefuehrt, fieldData, fieldDataError, competenceData, searchDate, specificConsultant, specificConsultantproject, getConsultant, getConsultantproject, sendProfilPicDownload, sendConsultantFileDownload, requestedConsultantFile4Download, requestedConsultantprojectFile4Download, sendUploadProfilePic, sendCreateConsultantFile, sendCreateConsultantprojectFile, sendCompetenceCreateRequest, sendQualificationCreateRequest, sendConsultantUpdate, sendDeleteConsultantFile, sendConsultantprojectUpdate, sendDeleteConsultantprojectFile}){
  
    const [guidedProgess, setGuidedProgess] = useState(0)
    const maxguidedProgess = 6

    let readOnly = false
    const history = useHistory();
    const [failedValidation, setFailedValidation] = useState({notnull: {}, regex: {}, semantic:  {}})
    const [error, setError] = useState("")

    const getConsultantValues = () => {
        if(Array.isArray(specificConsultant)){
            if(role === data.roles.MITARBEITER || specificConsultant[0].kuerzel === username){
                specificConsultant = specificConsultant.find(b => (b.status === data.status.BEARBEITUNG || b.status === data.status.FREIGABE_MITARBEITER) && b.kuerzel === username)
            }
            if (!specificConsultant || Array.isArray(specificConsultant)){specificConsultant = specificConsultant.find(b => b.status === data.status.FREIGABE_QUALITAETSSICHERUNG)}
        }
        if(specificConsultant && specificConsultant.kuerzel !== username){return {}}
        return defaultEncoder(specificConsultant)
    }
    // if (specificConsultantproject){readOnly=(readOnly ? readOnly : PermissionForbidden(role, username, specificConsultantproject.id, data.businessObject.consultantproject, specificConsultantproject))}
    const getConsultantprojectValues = () => {
        if (specificConsultantproject){readOnly=(readOnly ? readOnly : PermissionForbidden(role, username, specificConsultantproject.id, data.businessObject.consultantproject, specificConsultantproject))}
        if(Array.isArray(specificConsultantproject)){
            if(role === data.roles.MITARBEITER || specificConsultant[0]?.kuerzel === username){
                specificConsultantproject = specificConsultantproject.find(b => (b.status === data.status.BEARBEITUNG || b.status === data.status.FREIGABE_MITARBEITER) && b.berater == username)
            }
            if (!specificConsultantproject || Array.isArray(specificConsultantproject)){specificConsultantproject = specificConsultantproject.find(b => b.status === data.status.FREIGABE_QUALITAETSSICHERUNG)}
        }
        return defaultEncoder(specificConsultantproject)
    }

    const [consultantValues, setConsultantValues] = useState(getConsultantValues())
    const [consultantprojectValues, setConsultantprojectValues] = useState(getConsultantprojectValues())
    const [projectupdateDone, setProjectupdateDone] = useState({})
    const [tempConsultantprojects, setTempConsultantprojects] = useState({})
    const [filteredConsultantprojects, setFilteredConsultantprojects] = useState((consultantprojectList ? [...consultantprojectList?.filter(pro => (pro.berater === username && !projectupdateDone[pro.id] && pro.startdatum < new Date().toISOString().slice(0, 10) && (!pro.enddatum||pro.enddatum===null||pro.enddatum===undefined) && !pro.isKomplett))] : []))

    useEffect(() => {
        if (consultantprojectList){
            setFilteredConsultantprojects([...consultantprojectList?.filter(pro => (pro.berater === username && !projectupdateDone[pro.id] && pro.startdatum < new Date().toISOString().slice(0, 10) && (!pro.enddatum||pro.enddatum===null||pro.enddatum===undefined) && !pro.isKomplett))])
        }
    }, [consultantprojectList, username, projectupdateDone])

    useEffect(() => {
        if(successMsg){getConsultant(username);getConsultantprojectList()}
    }, [successMsg])

    useEffect(() => {
        setConsultantValues({});
        getConsultant(username);
    }, [])

    useEffect(() => {
        setConsultantValues(getConsultantValues())
    }, [specificConsultant])

    useEffect(() => {
        if (specificConsultant && specificConsultant.profilbild && specificConsultant.profilbild.id && !specificConsultant.profilbild.dateiData){sendProfilPicDownload(specificConsultant.id, specificConsultant.profilbild.id)}; // download the profile pic
        if (specificConsultant){readOnly=(readOnly ? readOnly : PermissionForbidden(role, username, specificConsultant.kuerzel, data.businessObject.consultant, specificConsultant))}
    }, [specificConsultant])

    useEffect(() => {
        setConsultantprojectValues(getConsultantprojectValues())
    }, [specificConsultantproject])

    const guidedNext = (specificCp) => {
        if ((Object.keys(consultantValues).length === 0 && consultantValues.constructor === Object) || (errorMsg && !errorMsg.includes('404'))){
            return;
        }
        if (guidedProgess === 0 || guidedProgess === 1){
            if(!somethingChanges(data.accessStatus.update, consultantValues, specificConsultant, data.businessObject.consultant)){setGuidedProgess(guidedProgess+1); return;}
            if(!validateValues(setError, consultantValues, failedValidation, setFailedValidation, data.businessObject.consultant)){return;}
        } 
        if (guidedProgess === 1){
            // updateConsultant()
            if (somethingChanges(data.accessStatus.update, consultantValues, specificConsultant, data.businessObject.consultant)){updateConsultant()}
        }
        if (guidedProgess === 2){
            getConsultantproject(specificCp)
        }
        if (guidedProgess === maxguidedProgess-1){
            if (somethingChanges(data.accessStatus.update, consultantValues, specificConsultant, data.businessObject.consultant)){updateConsultant()}
            setProjectupdateDone({...projectupdateDone, [consultantprojectValues.id]: true})
            if (somethingChanges(data.accessStatus.update, consultantprojectValues, specificConsultantproject, data.businessObject.consultantproject)){updateConsultantproject()}
            if (!objectList2AttrList(filteredConsultantprojects, 'id').some(f => Object.keys({...projectupdateDone, [consultantprojectValues.id]: true}).includes(f))){
                setGuidedProgess(2)
                return
            } else {
                setGuidedProgess(100)
                return
            }
        }
        if (guidedProgess < maxguidedProgess-1){
            setGuidedProgess(guidedProgess+1)
        } else {
            setGuidedProgess(100)
        }
    }

    const guidedPrev = () => {
        if (guidedProgess === 100) {
            setGuidedProgess(0)
        } else {
            setGuidedProgess(guidedProgess-1)
        }
    }
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [guidedProgess,error, errorMsg])

    const smallUpdateConsultantproject = (cpObject, cpValue) => {
        if (!validateValues(setError, consultantprojectValues, failedValidation, setFailedValidation)){return;}

        for (let key of Object.keys(cpValue)){
            
            if (!!cpValue[key]){
                if (Array.isArray(cpValue[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(cpValue[key], key, data.businessObject.consultantproject)
                    if (tempValues.length > 0 || (cpObject[key] && cpObject[key].length > 0)){
                        cpObject[key] =  tempValues
                    }
                } else {
                    cpObject[key] = cpValue[key]
                }
            }
        }
        cpObject.status = data.status.FREIGABE_MITARBEITER;
        delete tempConsultantprojects[cpObject.id]
        sendConsultantprojectUpdate(cpObject)

    }

    const updateConsultantproject = (overwriteStatus) => {
        if (!validateValues(setError, consultantprojectValues, failedValidation, setFailedValidation)){return;}
        let files2delete = []
        if (specificConsultantproject.dateien){
            for (let file of specificConsultantproject.dateien){
                if (!consultantprojectValues.dateien.find(f => f.id === file.id)){
                    files2delete.push(file.id)
                }
            }
        }

        for (let key of Object.keys(consultantprojectValues)){
            
            if (!!consultantprojectValues[key]){
                if (Array.isArray(consultantprojectValues[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(consultantprojectValues[key], key, data.businessObject.consultantproject)
                    if (tempValues.length > 0 || (specificConsultantproject[key] && specificConsultantproject[key].length > 0)){
                        specificConsultantproject[key] =  tempValues
                    }
                } else {
                    specificConsultantproject[key] = consultantprojectValues[key]
                }
            }
        }
        if(overwriteStatus){
            specificConsultantproject.status = overwriteStatus
        } else {
            specificConsultantproject.status = data.status.BEARBEITUNG; 
        }
        sendConsultantprojectUpdate(specificConsultantproject)

        for (let id of files2delete){
            sendDeleteConsultantprojectFile(specificConsultantproject.id, id);
        }

        if (specificConsultantproject.dateien){
            for (let file of specificConsultantproject.dateien){
                if(!equals(file,businessobject_data.datei.default) && !file.id && file.dateiData){
                    sendCreateConsultantprojectFile(specificConsultantproject.id, file)
                }
            }
        }
    }

    const updateConsultant = (overwriteStatus) => {
        if (!validateValues(setError, consultantValues, failedValidation, setFailedValidation)){return;}
        let files2delete = []
        if (specificConsultant.dateien){
            for (let file of specificConsultant.dateien){
                if (!consultantValues.dateien?.find(f => f.id === file.id)){
                    files2delete.push(file.id)
                }
            }
        }
        if (specificConsultant.profilbild && equals(consultantValues.profilbild, businessobject_data.datei.default)){
            files2delete.push(specificConsultant.profilbild.id)
        }

        for (let key of Object.keys(consultantValues)){
            if (businessobject_data.types[key] && businessobject_data.types[key].unique){
                if (!consultantValues['email']){setError(message_data.error.pflegen.unsetUser);return false}
                if (consultantList){
                    for (let b of consultantList){
                        if (b['email'] === consultantValues['email'] && consultantValues['email'] !== specificConsultant['email']) {
                            setError(message_data.error.pflegen.unsetUser)
                            return false
                        }
                    }
                }
            }
            
            if (!!consultantValues[key]){
                if (Array.isArray(consultantValues[key])){
                    //filter default values out of the array
                    let tempValues = arrayDefaultFilter(consultantValues[key], key, data.businessObject.consultant)
                    if (tempValues.length > 0 || (specificConsultant[key] && specificConsultant[key].length > 0)){
                        specificConsultant[key] =  tempValues
                    }
                } else {
                    specificConsultant[key] = consultantValues[key]
                }
            }
        }
        if(overwriteStatus){
            specificConsultant.status = overwriteStatus
        } else {
            specificConsultant.status = data.status.BEARBEITUNG; 
        }
        sendConsultantUpdate(specificConsultant)

        for (let id of files2delete){
            sendDeleteConsultantFile(specificConsultant.id, id);
        }

        if (specificConsultant.profilbild && !specificConsultant.profilbild.id && specificConsultant.profilbild.dateiData){
            sendUploadProfilePic(specificConsultant.id, specificConsultant.profilbild.dateiData)
        }
        if (specificConsultant.dateien){
            for (let file of specificConsultant.dateien){
                if(!equals(file,businessobject_data.datei.default) && !file.id && file.dateiData){
                    sendCreateConsultantFile(specificConsultant.id, file)
                }
            }
        }

    }
    useEffect(() => {
        if (updatedId){
            let furtherUploads = false;
            if (specificConsultant.profilbild && !equals(specificConsultant.profilbild,businessobject_data.berater.default.profilbild)  && !specificConsultant.profilbild.id && specificConsultant.profilbild.dateiData){
                furtherUploads = true;
                sendUploadProfilePic(updatedId, specificConsultant.profilbild.dateiData)
            }
            
            if (!furtherUploads) {
                setfurtherSuccess();
            }
        }
    },[updatedId])

    const optionsDataByType = optionsDataByTypeInit(competenceData, fieldData2options(fieldData))
    const getOptions = getOptionsInit(optionsDataByType)

    const displayCategorie = (cat) => {
        return cat[0].toUpperCase() + cat.substring(1).replace('ae', 'ä').replace('oe', 'ö').replace('_', ' ')
    }

    const onCompetenceCreateReq = (competence) => {
        sendCompetenceCreateRequest({...competence, status: data.status.FREIGABE_MITARBEITER})
    }

    const onQualificationCreateReq = (qualification) => {
        const tempQ = {}
        for (let qDefaultKey of Object.keys(businessobject_data.qualifikation.default)){
            if (!['druck', 'status', 'kompetenzenId'].includes(qDefaultKey) && !equals(qualification[qDefaultKey], businessobject_data.qualifikation.default[qDefaultKey])){
                tempQ[qDefaultKey] = qualification[qDefaultKey]
            }
        }
        sendQualificationCreateRequest({...tempQ, status: data.status.FREIGABE_MITARBEITER, druck: false})
    }

    const onFileDownload = (fileId) => {
        if(fileId){
            sendConsultantFileDownload(consultantValues.id, fileId)
        }
    }

    useEffect(() => {if (consultantValues && pmQualificationSuccess){setConsultantValues({...consultantValues, qualifikationen:[...consultantValues['qualifikationen'], pmQualificationSuccess]})}}, [pmQualificationSuccess])
    useEffect(() => {if (consultantValues && pmCompetenceSuccess){setConsultantValues({...consultantValues, kompetenzen:[...consultantValues['kompetenzen'], pmCompetenceSuccess]})}}, [pmCompetenceSuccess])

    function returnConsultantInput(type, index, key, presentation){
        if (type){
        switch (type.type){
            case data.inputTypes.advanced.DROPDOWN:
                let optionsDrop = optionsDataByType(key)
                return <DropdownInput key={index} multiSelect={!!type.multiSelect} value={consultantValues[key]} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} setValue={v => setConsultantValues({...consultantValues, [key]: v})} options={optionsDrop} searchable={(type.searchable ? true : false)} />
            case data.inputTypes.advanced.INLINEDEFINE:
                let optionsObjInline = getOptions(key)
                return <InLineInput key={index} presentation={presentation} name={key} create={!!type.create} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} setValue={v => setConsultantValues({...consultantValues, [key]: v})} value={consultantValues[key]} defaultObject={{...businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].default}} presentationObject={businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].fields} optionsObject={optionsObjInline} invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, consultantValues[key])} />
            case data.inputTypes.advanced.TABLE:
                let optionsObjTable = getOptions(key)
                let dropdownOptions = optionsDataByType(key)
                if (key === 'kompetenzen'){
                    completeCompetenceAfterUpdate((v) => setConsultantValues({...consultantValues, [key]: v}), consultantValues[key], competenceData, type.dataAttribute, ['titel', 'kategorie'], type.idProperty)
                    return (
                        <div className="inner-contents" key={index}>
                            {(['TECHNIK', 'THEMA']).map((k, i) => (
                                <TableInput key={index*100+i} presentation={enum2label(k)} name={(key+k)} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} disabledObject={{kategorie: true, titel: true}} onCreate={(v) => (onCompetenceCreateReq({kategorie: k, titel: v}))} presentationObject={businessobject_data.kompetenz.zoFields} value={((consultantValues && consultantValues[key]) ? consultantValues[key].filter(c => c.kategorie === k) : [])} options={optionsDataByType(key, k)} optionsObject={optionsObjTable} setValue={l => {let c = (consultantValues[key] ? consultantValues[key].filter(c => c.kategorie !== k) : []); c.push(...l); setConsultantValues({...consultantValues, [key]: c})}} valueProperty={type.idProperty ? type.idProperty : key} />
                            ))}
                        </div>
                    )
                } else {
                    completeCompetenceAfterUpdate((v) => setConsultantValues({...consultantValues, [key]: v}), consultantValues[key], competenceData, type.dataAttribute, ['titel', 'name'], type.idProperty)
                    return <TableInput key={index} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} presentationObject={businessobject_data.sprache.zoFields} disabledObject={{titel: true}} value={consultantValues[key]} setValue={v => setConsultantValues({...consultantValues, [key]: v})} options={dropdownOptions} optionsObject={optionsObjTable} valueProperty={type.idProperty ? type.idProperty : key} />
                }
            case data.inputTypes.advanced.ROWDEFINE:
                let optionsObjRow = getOptions(key)
                let disabledObj = {}
                for (let qId in consultantValues[key]){
                    if (key === 'qualifikationen'){
                        if(!consultantValues[key][qId].typ){disabledObj[qId]={...disabledObj[qId], qualifikationTitel:true}}
                        if (consultantValues[key][qId].qualifikationId){
                            for (let qK of Object.keys(businessobject_data.qualifikation.default)){
                                disabledObj[qId]={...disabledObj[qId], [qK]:true};
                            }
                        }
                        optionsObjRow = {...optionsObjRow, [qId]: {qualifikationTitel: optionsDataByType("qualifikationTitel", consultantValues[key][qId][businessobject_data.types.qualifikationTitel.filterBy])}}
                        if (consultantValues[key][qId].status === data.status.FREIGABE_MITARBEITER){
                            optionsObjRow = {...optionsObjRow, [qId]: {...optionsObjRow[qId], qualifikationTitel: [{value: consultantValues[key][qId].qualifikationId, label:((competenceData&&competenceData['qualifikation']&&optionsFilter(competenceData['qualifikation'][consultantValues[key][qId][businessobject_data.types.qualifikationTitel.filterBy]], 'id', consultantValues[key][qId].qualifikationId))[0] ? optionsFilter(competenceData['qualifikation'][consultantValues[key][qId][businessobject_data.types.qualifikationTitel.filterBy]], 'id', consultantValues[key][qId].qualifikationId)[0].qualifikationTitel : consultantValues[key][qId].qualifikationId)}]}}
                        }
                    }
                    if (key === 'bildungswege'){
                        if(!consultantValues[key][qId].abschlussId){disabledObj[qId]={...disabledObj[qId], bildungstitelId:true, enddatum:true, institutId:true, arbeit:true, ort:true}}
                        for (let k of Object.keys(consultantValues[key][qId])){
                            if (businessobject_data.types[k] && businessobject_data.types[k].filterBy){
                                optionsObjRow = {...optionsObjRow, [qId]: {[k]: optionsDataByType(k, consultantValues[key][qId][businessobject_data.types[k].filterBy], businessobject_data.types[k].filterBy)}}
                            }
                        }
                    }
                }
                if (key === 'qualifikationen'){
                    completeCompetenceAfterUpdate((v) => setConsultantValues({...consultantValues, [key]: v}), consultantValues[key], competenceData, type.dataAttribute, ['qualifikationId'], 'qualifikationTitel', true, true)
                    completeCompetenceAfterUpdate((v) => setConsultantValues({...consultantValues, [key]: v}), consultantValues[key], competenceData, type.dataAttribute, ['qualifikationTitel'], 'qualifikationId', true)
                    return (
                        <RowInput key={index} onCreate={(i,v) => {onQualificationCreateReq({...consultantValues[key][i], titel:v})}} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} presentation={presentation} name={key} presentationObject={businessobject_data[(type.dataAttribute ? type.dataAttribute : key)].zoFields} defaultObject={{...businessobject_data[(type.dataAttribute ? type.dataAttribute : key)].default, ...businessobject_data[(type.dataAttribute ? type.dataAttribute : key)].zoDefault}} optionsObject={optionsObjRow} value={consultantValues[key]} setValue={v => setConsultantValues({...consultantValues, [key]: v})} disabledObject={disabledObj}  invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, consultantValues[key])}/>
                    )
                } else {
                    completeCompetenceAfterUpdate((v) => setConsultantValues({...consultantValues, [key]: v}), consultantValues[key], competenceData, 'bildungstitel', ['abschlussId'], 'bildungstitelId')
                    return (
                        <RowInput key={index} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} presentationObject={businessobject_data[(type.dataAttribute ? type.dataAttribute : key)].fields} presentation={presentation} name={key} defaultObject={businessobject_data[(type.dataAttribute ? type.dataAttribute : key)].default} optionsObject={optionsObjRow} value={consultantValues[key]} setValue={v => setConsultantValues({...consultantValues, [key]: v})} disabledObject={disabledObj} invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, consultantValues[key])} />
                    )
                }
            case data.inputTypes.advanced.FILE:
                return <FileInput key={index} picture={!!type.picture} requestedFile={requestedConsultantFile4Download} downloadFile={onFileDownload} multiple={!!type.multiSelect} dateitypOptions={optionsDataByType('dateityp')} presentation={presentation} field={key} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} value={consultantValues[key]} setValue={v => setConsultantValues({...consultantValues, [key]: v})}  />
            case data.inputTypes.advanced.OBJECTFK: 
                return <ObjectFKInput key={index} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} presentation={presentation} name={key} value={consultantValues[key]} setValue={v => setConsultantValues({...consultantValues, [key]: v})} multiSelect={type.multiSelect} create={type.create} objectList={(consultantList ? consultantList : [])} businessObject={type.businessObject} valueProperty={type.selectProperty} presentationProperty={type.selectProperty} filter={((type.filterBy && type.filterValue) ? {attr: type.filterBy, value: type.filterValue} : null)} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, consultantValues[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? consultantValues[businessobject_data.types[key].semanticCheck.compareTo] : null))} />
            default:
                return <InputByType key={index} passKey={index} type={type.type} asNumber={type.asNumber} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} value={(consultantValues ? consultantValues[key] : '')} maxChar={type.maxChar} name={key} presentation={presentation} setValue={v => setConsultantValues({...consultantValues, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, (consultantValues ? consultantValues[key] : null), (businessobject_data.types[key]?.semanticCheck?.compareTo ? consultantValues[businessobject_data.types[key].semanticCheck.compareTo] : null))} />
        }
        } else {
            return <TextInput key={index} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} value={consultantValues[key]} name={key} presentation={presentation} setValue={v => setConsultantValues({...consultantValues, [key]: v})} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, consultantValues[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? consultantValues[businessobject_data.types[key].semanticCheck.compareTo] : null))} />
        }
    }

    function returnConsultantprojectInput(type, index, key, presentation, tempValues, setTempValues){
        if (type){
        switch (type.type){
            case data.inputTypes.advanced.DROPDOWN:
                let optionsDrop = optionsDataByType(key)
                return <DropdownInput key={index} multiSelect={!!type.multiSelect} value={(tempValues?tempValues[key]:consultantprojectValues[key])} presentation={presentation} name={key} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} setValue={(setTempValues ? v => setTempValues({...tempValues, [key]: v}) : v => setConsultantprojectValues({...consultantprojectValues, [key]: v}))} options={optionsDrop} searchable={(type.searchable ? true : false)} />
            case data.inputTypes.advanced.INLINEDEFINE:
                let optionsObjInline = getOptions(key)
                return <InLineInput key={index} presentation={presentation} name={key} create={!!type.create} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} setValue={(setTempValues ? v => setTempValues({...tempValues, [key]: v}) : v => setConsultantprojectValues({...consultantprojectValues, [key]: v}))} value={(tempValues?tempValues[key]:consultantprojectValues[key])} defaultObject={{...businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].default}} presentationObject={businessobject_data[(businessobject_data.types[key].dataAttribute ? businessobject_data.types[key].dataAttribute : key)].fields} optionsObject={optionsObjInline} invalidObject={checkInvalidation(key, failedValidation, setFailedValidation, consultantprojectValues[key])} />
            case data.inputTypes.advanced.TABLE:
                let optionsObjTable = getOptions(key)
                let dropdownOptions = optionsDataByType(key)
                completeCompetenceAfterUpdate((v) => setConsultantprojectValues({...consultantprojectValues, [key]: v}), consultantprojectValues[key], competenceData, type.dataAttribute, ['titel', 'kategorie'], type.idProperty)
                return (
                    <div className="inner-contents" key={index}>
                        {(['TECHNIK', 'TAETIGKEITSBEREICH', 'SOFTWARE']).map((k, i) => (
                            <TableInput key={index*100+i} presentation={enum2label(k)} name={(key+k)} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} disabledObject={{kategorie: true, titel: true}} onCreate={(v) => (onCompetenceCreateReq({kategorie: k, titel: v}))} presentationObject={businessobject_data.kompetenz.zoFields} value={((consultantprojectValues && consultantprojectValues[key]) ? consultantprojectValues[key].filter(c => c.kategorie === k) : [])} options={optionsDataByType(key, k)} optionsObject={optionsObjTable} setValue={l => {let c = consultantprojectValues[key].filter(c => c.kategorie !== k); c.push(...l); setConsultantprojectValues({...consultantprojectValues, [key]: c})}} valueProperty={type.idProperty ? type.idProperty : key} />
                        ))}
                    </div>
                )
            case data.inputTypes.advanced.FILE:
                return <FileInput key={index} picture={!!type.picture} requestedFile={requestedConsultantprojectFile4Download} downloadFile={onFileDownload} multiple={!!type.multiSelect} dateitypOptions={optionsDataByType('dateityp')} presentation={presentation} field={key} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} value={consultantprojectValues[key]} setValue={v => setConsultantprojectValues({...consultantprojectValues, [key]: v})}  />
            case data.inputTypes.advanced.OBJECTFK:
                const fkKundeId = (fkLists && fkLists.customerprojects && fkLists.customerprojects.find(obj => obj.id === consultantprojectValues.kundenprojektId) ? fkLists.customerprojects.find(obj => obj.id === consultantprojectValues.kundenprojektId)?.kundeId  : null)
                const objectList = (fkKundeId && optionsFilter(fkLists.customers, 'id', fkKundeId)[0] ? optionsFilter(fkLists.customers, 'id', fkKundeId)[0].ansprechpartner : undefined)
                return <ObjectFKInput key={index} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} presentation={presentation} name={key} value={consultantprojectValues[key]} setValue={v => setConsultantprojectValues({...consultantprojectValues, [key]: v})} multiSelect={type.multiSelect} create={type.create} objectList={objectList} businessObject={type.businessObject} valueProperty={type.selectProperty} presentationProperty={(type.presentationProperty ? type.presentationProperty : type.selectProperty)} filter={((type.filterBy && type.filterValue) ? {attr: type.filterBy, value: type.filterValue} : null)} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, consultantprojectValues[key], (businessobject_data.types[key]?.semanticCheck?.compareTo ? consultantValues[businessobject_data.types[key].semanticCheck.compareTo] : null))} />
            default:
                return <InputByType key={index} passKey={index} type={type.type} asNumber={type.asNumber} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} value={(tempValues?tempValues[key]:(consultantprojectValues ? consultantprojectValues[key] : ''))} maxChar={type.maxChar} name={key} presentation={presentation} setValue={(setTempValues ? v => setTempValues({...tempValues, [key]: v}) : v => setConsultantprojectValues({...consultantprojectValues, [key]: v}))} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, (consultantprojectValues ? consultantprojectValues[key] : null), (businessobject_data.types[key]?.semanticCheck?.compareTo ? consultantValues[businessobject_data.types[key].semanticCheck.compareTo] : null))} />
        }
        } else {
            return <TextInput key={index} readOnly={checkReadOnly(key, readOnly, data.accessStatus.update)} value={(tempValues?tempValues[key]:consultantprojectValues[key])} name={key} presentation={presentation} setValue={(setTempValues ? v => setTempValues({...tempValues, [key]: v}) : v => setConsultantprojectValues({...consultantprojectValues, [key]: v}))} isInvalid={checkInvalidation(key, failedValidation, setFailedValidation, (tempValues?tempValues[key]:consultantprojectValues[key]), (businessobject_data.types[key]?.semanticCheck?.compareTo ? (tempValues?tempValues:consultantprojectValues)[businessobject_data.types[key].semanticCheck.compareTo] : null))} />
        }
    }

    function returnView(){
        let attr2display;
        switch (guidedProgess) {
            case 1:
                // Overview Beraterqualifikation
                // speichern und API
                attr2display = {schwerpunkte: ['techSchwerpunkt', 'fachSchwerpunkt'], kompetenzen: ['kompetenzen'], qualifikationen: ['dateien', 'qualifikationen']}
                return <div className="inner-content-view">
                    <h4>Hier werden deine Beraterkompetenzen und -qualifikationen gepflegt</h4>
                    <div className="main-inputs" id="guided-consultant">
                        {Object.keys(attr2display).map((cat, j) => {
                            return (
                                <div className="input-categorie" key={j} >
                                    <p className="input-categorie-header">Sind die {displayCategorie(cat)} noch aktuell?</p>
                                    <div className="input-categorie-body">{attr2display[cat].map((key, index) => {
                                            return returnConsultantInput(businessobject_data.types[key], index, key, searchObject(businessobject_data.berater.fields, key))
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <button className="small" disabled={readOnly} onClick={() => history.push("/pflegen/berater/"+username)}><span title="Die bisherigen Änderungen werden nicht gespeichert">Alle Details bearbeiten</span></button> */}
                </div>
            case 2:
                // Overview Beraterprojekte, List (filtered by username and current Date)
                attr2display = [['isReferenz', 'druck', 'isVorlage'], 'enddatum', 'projektunterbrechungen']
                function getCustomerName(cp){
                    const fkKundeId = (fkLists && fkLists.customerprojects ? fkLists.customerprojects.find(obj => obj.id === cp.kundenprojektId)?.kundeId  : '')
                    return (fkKundeId && fkLists && fkLists.customers ? fkLists.customers.find(obj => obj.id === fkKundeId)?.name : '')
                }
                function getCustomerprojectName(cp){
                    return (fkLists && fkLists.customerprojects ? fkLists.customerprojects.find(obj => obj.id === cp.kundenprojektId).name  : '')
                }

                if (filteredConsultantprojects.length === 0){setGuidedProgess(100)}
                
                return <div className="inner-content-view">
                    <h4>Du siehst eine Übersicht über deine aktuell laufenden Beraterprojekte</h4>
                    <button className="small right" onClick={() => history.push("/pflegen/beraterprojekt/neu")}>Neues Beraterprojekt</button>
                    <div className="main-inputs" id="guided-consultantproject">
                        {filteredConsultantprojects.map((cp,i) => {
                            if (!tempConsultantprojects[cp.id]){setTempConsultantprojects({...tempConsultantprojects, [cp.id]: {...defaultEncoder(cp)}})}
                            return <div className="input-categorie" key={i} >
                                <div className="input-categorie-header">
                                    <div><strong>{getCustomerName(cp)}</strong>, {getCustomerprojectName(cp)}<br />{cp.symbol}, {cp.name}</div>
                                    <small>beraterprojekt/{cp.id}</small>
                                </div>
                                <div className="input-categorie-body" id="guided-summary">{attr2display.map((key, index) => {
                                        if (Array.isArray(key)){
                                            return <div className="input-group" key={index}>
                                                {key.map((subfield, j) => (returnConsultantprojectInput(businessobject_data.types[subfield], index*100+j, subfield, searchObject(businessobject_data.beraterprojekt.fields, subfield), tempConsultantprojects[cp.id], (tempValues) => setTempConsultantprojects({...tempConsultantprojects, [cp.id]: tempValues}))))}
                                            </div>
                                        }
                                        return returnConsultantprojectInput(businessobject_data.types[key], index, key, searchObject(businessobject_data.beraterprojekt.fields, key), tempConsultantprojects[cp.id], (tempValues) => setTempConsultantprojects({...tempConsultantprojects, [cp.id]: tempValues}))
                                    })}
                                </div>
                                <div className="input-categorie-footer">
                                    <button className="micro" onClick={() => {setProjectupdateDone({...projectupdateDone, [cp.id]: true})}}>Ignorieren</button>
                                    <button className="micro" onClick={() => {smallUpdateConsultantproject(cp, {...tempConsultantprojects[cp.id], enddatum:new Date().toISOString().slice(0, 10)})}}>Abschließen</button>
                                    <button className="micro" onClick={() => {smallUpdateConsultantproject(cp, tempConsultantprojects[cp.id])}} disabled={tempConsultantprojects[cp.id] && !somethingChanges(data.accessStatus.update, tempConsultantprojects[cp.id], cp, data.businessObject.consultantproject)}>Speichern</button>
                                    <button className="micro" onClick={() => {guidedNext(cp.id)}}>Anpassen</button>
                                </div>
                            </div>
                        })}
                    </div>
                </div> 
            case 3:
                // selected Beraterprojekt
                // Projektstandort, Ansprechpartner
                attr2display = {projektdetails: ['projektstandort', 'kommentar'], kontaktdaten: ['ansprechpartnerId']}
                if (Object.keys(consultantprojectValues).length === 0 && consultantprojectValues.constructor === Object){
                    readOnly = true
                }
                return <div className="inner-content-view">
                    <h4>Pflege dein ausgewähltes Beraterprojekt {consultantprojectValues.symbol}</h4>
                    {readOnly && <div className="error">Es konnte kein Beraterprojekt geladen werden, versuche erneut eines auszuwählen <button className="small" onClick={() => setGuidedProgess(2)}>Zurück</button></div>}
                    <div className="main-inputs" id="guided-consultantproject">
                        {Object.keys(attr2display).map((cat, j) => {
                            return (
                                <div className="input-categorie" key={j} >
                                    <p className="input-categorie-header">Sind die {displayCategorie(cat)} noch aktuell?</p>
                                    <div className="input-categorie-body">{attr2display[cat].map((key, index) => {
                                            return returnConsultantprojectInput(businessobject_data.types[key], index, key, searchObject(businessobject_data.beraterprojekt.fields, key))
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <button className="small" disabled={readOnly} onClick={() => history.push("/pflegen/beraterprojekt/" + consultantprojectValues.id)}>Alle Details bearbeiten</button> */}
                </div> 
            case 4:
                // selected Beraterprojekt
                // Beschreibungen
                attr2display = {beschreibungen: ['kurzBeschreibung', 'langBeschreibung', 'themenBeschreibung', 'aufgabenBeschreibung']}
                return <div className="inner-content-view">
                    <h4>Pflege dein ausgewähltes Beraterprojekt {consultantprojectValues.symbol}</h4>
                    <div className="main-inputs" id="guided-consultantproject">
                        {Object.keys(attr2display).map((cat, j) => {
                            return (
                                <div className="input-categorie" key={j} >
                                    <p className="input-categorie-header">Sind die {displayCategorie(cat)} zum Projekt noch aktuell?</p>
                                    <div className="input-categorie-body">{attr2display[cat].map((key, index) => {
                                            return returnConsultantprojectInput(businessobject_data.types[key], index, key, searchObject(businessobject_data.beraterprojekt.fields, key))
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <button className="small" disabled={readOnly} onClick={() => history.push("/pflegen/beraterprojekt/" + consultantprojectValues.id)}><span title="Die bisherigen Änderungen werden nicht gespeichert">Alle Details bearbeiten</span></button> */}
                </div> 
            case 5:
                // selected Beraterprojekt
                // Kompetenzen (Technik, Tätigkeitsbereich, Software)
                attr2display = {kompetenzen: ['kompetenzen']}
                return <div className="inner-content-view">
                    <h4>Pflege dein ausgewähltes Beraterprojekt {consultantprojectValues.symbol}</h4>
                    <div className="main-inputs" id="guided-consultantproject">
                        {Object.keys(attr2display).map((cat, j) => {
                            return (
                                <div className="input-categorie" key={j} >
                                    <p className="input-categorie-header">Sind die im Projekt verwendeten {displayCategorie(cat)} noch aktuell?</p>
                                    <div className="input-categorie-body">{attr2display[cat].map((key, index) => {
                                            return returnConsultantprojectInput(businessobject_data.types[key], index, key, searchObject(businessobject_data.beraterprojekt.fields, key))
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <button className="small" disabled={readOnly} onClick={() => history.push("/pflegen/beraterprojekt/" + consultantprojectValues.id)}><span title="Die bisherigen Änderungen werden nicht gespeichert">Alle Details bearbeiten</span></button> */}
                </div> 
            case 100:
                return <div className="inner-content-view">
                    <b>Sie haben alle Details aktualisiert.</b> <br /> <br /> Wählen Sie ein Geschäftsobjekt über die Navigation um eines zu suchen, legen Sie ein <button onClick={() => history.push("/pflegen/beraterprojekt/neu")}>Neues Beraterprojekt</button> an oder gehen sie zu <button onClick={() => history.push('/drucken')} >Beraterprofil Drucken</button>
                </div>
            case 0:
            default:
                // Overview Beraterstammdaten
                attr2display = {kontaktdaten: ['email', 'profilbild', 'adressen'], zustaendigkeiten: ['beraterfunktionId', 'beraterlabelId', 'betreuer'], persoenlichen_Details: ['motto', 'projektexpertise', 'hobbies', 'sprachen']}
                return <div className="inner-content-view">
                    <h4>Hallo{consultantValues && consultantValues.vorname ? ' ' + consultantValues.vorname : ''}, hier werden deine Beraterstammdaten gepflegt</h4>
                    <div className="main-inputs" id="guided-consultant">
                        {Object.keys(attr2display).map((cat, j) => {
                            return (
                                <div className="input-categorie" key={j} >
                                    <p className="input-categorie-header">Sind die {displayCategorie(cat)} noch aktuell?</p>
                                    <div className="input-categorie-body">{attr2display[cat].map((key, index) => {
                                            return returnConsultantInput(businessobject_data.types[key], index, key, searchObject(businessobject_data.berater.fields, key))
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <button className="small" disabled={readOnly} onClick={() => history.push("/pflegen/berater/"+username)}>Alle Details bearbeiten</button> */}
                </div>
        }
    }
    if ((Object.keys(consultantValues).length === 0 && consultantValues.constructor === Object) || (errorMsg && !errorMsg.includes('404'))){
        return(
            <div className="content">
                <PflegenKopfzeile_Gefuehrt role={role} accessStatus={data.accessStatus.custom} businessObject={businessObject} successMsg={successMsg} businessObjectFunctions={{guidedNext, guidedPrev}} guidedProgessState={guidedProgess} setGuidedProgessState={setGuidedProgess} maxguidedProgess={maxguidedProgess} consultantprojectObject={null} consultantObject={null} />
            <div className="inner-content" id="guided">
                <h3>Geführte Pflege</h3>
                {(errorMsg && !errorMsg.includes('404')) ? <div><strong className="error">{errorMsg}</strong></div> : <div className='inner-contents'>
                <div><strong>Für Sie ist noch kein Berater angelegt</strong></div>
                {(!PermissionForbidden(role, username, 'neu', businessObject) ? <button onClick={() => history.push('/pflegen/berater/neu')}>Berater anlegen</button> : <p>Kontaktieren Sie das BeckOffice um einen Berater anzulegen.</p>)}
                </div>}
                {/* <div className="button-group">
                    <button id="main" onClick={guidedNext} disabled={true}>{((guidedProgess >= maxguidedProgess) ? 'Speichern' : 'Weiter')}</button>
                </div> */}
            </div>
                <PflegenFusszeile accessStatus={data.accessStatus.custom} date={searchDate} />
            </div>
        )
    }
    return(
        <div className="content">
            <PflegenKopfzeile_Gefuehrt role={role} accessStatus={data.accessStatus.custom} fkLists={fkLists} businessObject={businessObject} successMsg={successMsg} businessObjectFunctions={{guidedNext, guidedPrev}} guidedProgessState={guidedProgess} setGuidedProgessState={setGuidedProgess} maxguidedProgess={maxguidedProgess} consultantprojectObject={consultantprojectValues} consultantObject={consultantValues} />
        <div className="inner-content" id="guided">
            {errorMsg && <div id="server-error"><strong className="error">{errorMsg}</strong></div>}
            <h3>Geführte Pflege</h3>
            {error && <div id="client-error"> <br /> <small className="error">{error}</small></div>}
            {returnView()}
            <div className="button-group">
                {(guidedProgess === 1 && (somethingChanges(data.accessStatus.update, consultantValues, specificConsultant, data.businessObject.consultant) || specificConsultant.status.BEARBEITUNG)) && <button onClick={() => {updateConsultant(data.status.FREIGABE_MITARBEITER); setGuidedProgess(2)}} disabled={readOnly}>Speichern & Freigeben</button>}
                {guidedProgess === maxguidedProgess-1 && <button onClick={() => {updateConsultantproject(data.status.FREIGABE_MITARBEITER); setGuidedProgess(2)}} disabled={readOnly}>Speichern & Freigeben</button>}
                {(guidedProgess !== 2 && guidedProgess !== 100) && <button id="main" onClick={guidedNext} disabled={readOnly}>{(((guidedProgess >= maxguidedProgess || guidedProgess===1) && somethingChanges(data.accessStatus.update, consultantValues, specificConsultant, data.businessObject.consultant)) ? 'Speichern' : 'Weiter')}</button>}
            </div>
        </div>
            <PflegenFusszeile accessStatus={data.accessStatus.custom} date={searchDate} />
        </div>
    )
}

/**
 * Weist den verwendeten lokalen Variablen, variablen aus dem State zu
 * 
 * @param {*} state - aktueller State
 * @param {*} ownProps - weitere Variablen
 * @returns den aktuellen State als Komponentenvariablen
 */
 const mapStateToProps = (state, ownProps) => ({
    role: state.user.role,
    username: state.user.username,
    specificConsultant: state.consultants.specific,
    consultantList: state.consultants.list,
    updatedId: state.consultants.createdId,
    consultantprojectList: state.consultantprojects.list,
    fkLists: {customers: state.customers.list, customerprojects: state.customerprojects.list},
    specificConsultantproject: state.consultantprojects.specific,
    requestedConsultantFile4Download: state.consultants.requestedFile,
    requestedConsultantprojectFile4Download: state.consultantprojects.requestedFile,
    errorMsg: state.businessobjects.error,
    fieldData: state.fielddata.data,
    fieldDataError: state.fielddata.error,
    competenceData: state.competences.list,
    successMsg: state.businessobjects.success,
    pmCompetenceSuccess: state.competences.competencePMCompetenceRequest,
    pmQualificationSuccess: state.competences.competencePMQualificationRequest,
    ...ownProps
})

/**
 * Weist den verwendeten lokalen Funktionen, Funktionen aus dem Redux zu
 * 
 * @returns Die gemappten Funktionen
 */
const mapDispatchToProps = dispatch => ({
    getConsultant: (id) => dispatch(sendGetConsultantRequest(id)),
    sendConsultantUpdate: (consultant) => dispatch(sendUpdateConsultantRequest(consultant)),
    getConsultantproject: (id) => dispatch(sendGetConsultantprojectRequest(id)),
    getConsultantprojectList: () => dispatch(sendGetConsultantprojectListRequest()),
    sendConsultantprojectUpdate: (consultantproject) => dispatch(sendUpdateConsultantprojectRequest(consultantproject)),
    sendProfilPicDownload: (id, fileId) => dispatch(sendConsultantFileDownload(id, fileId, true)),
    sendUploadProfilePic: (id, file) => dispatch(sendConsultantFileUpload(id, file, true)),
    sendConsultantFileDownload: (id, fileId) => dispatch(sendConsultantFileDownload(id, fileId, false)),
    sendCreateConsultantFile: (id, file) => dispatch(sendCreateConsultantFileRequest(id, file)),
    sendDeleteConsultantFile: (id, fileId) => dispatch(sendDeleteConsultantFileRequest(id, fileId)),
    sendConsultantprojectFileDownload: (id, fileId) => dispatch(sendConsultantprojectFileDownload(id, fileId, false)),
    sendCreateConsultantprojectFile: (id, file) => dispatch(sendCreateConsultantprojectFileRequest(id, file)),
    sendDeleteConsultantprojectFile: (id, fileId) => dispatch(sendDeleteConsultantprojectFileRequest(id, fileId)),
    sendCompetenceCreateRequest: (competence) => dispatch(sendCreatePMCompetenceRequest(competence)),
    sendQualificationCreateRequest: (competence) => dispatch(sendCreatePMQualificationRequest(competence)),
    setfurtherSuccess: () => dispatch(setSuccess())
}) 

export default connect(mapStateToProps, mapDispatchToProps)(Pflegen_Gefuehrt)