import React, {useEffect} from 'react'
import { useHistory, useLocation } from "react-router-dom";
import businessobject_data from '../../data/businessobject_data'

import { DateSearch, HeaderButtons, SingleSelect, Tabs } from '../_helpers/Kopfzeile'
import data from '../../data/_data'
import Select from 'react-select';
import { PermissionForbidden } from './../_helpers/ReadPermission';
import { optionsFilter } from './../_helpers/functions';

// import { Search } from '../_helpers/Kopfzeile'

/**
 * Kopfzeilen-Komponente für die Seiten "Beraterprofil Drucken", Rendert alle folegenden Komponenten zur Einstellung der Filter- und Suchkriterien
 * 
 * @author DHR
 * @param {String} geschaeftsobjekt - aktuelles Geschäftsobjekt der Route
 * @returns {HTML} zu Rendernde Komponente:
 */
function DruckenKopfzeile ({role, id, accessStatus, readOnly, object, setProfileView, profileView, setConsultantView, consultantView, onCreate, setSearchAttr, setSearchVal, searchAttr, searchVal, setSearchAttr2, setSearchVal2, searchAttr2, onlyAvailableConsultant, setOnlyAvailableConsultant, searchVal2,  statusFilter, setStatusFilter, setTab, tabs, tab, successMsg, loggedInUsername, competenceFilterData, setSearchFKAttr, setSearchStartDate, setSearchEndDate, searchStartDate, searchEndDate, onFilter, onFilterReset}){

    var options = []
    for (var value of Object.keys(businessobject_data.berater.searchAttributes)){
        options.push({value: value, label: businessobject_data.berater.searchAttributes[value]})
    }// [{value: '',label: ''}]
    let history = useHistory();

    useEffect(() => {if (accessStatus === data.accessStatus.search && !searchAttr){setSearchAttr(options[0].value)}})

    if(new URLSearchParams(useLocation().search).get('reload')){
        history.replace({
            search: "",
        })
        history.go(0)
    }

    if (accessStatus === data.accessStatus.search){
        return(
            <div className="content-header" id="print-search">
                <div className="search">
                    <div className="textlabel"><b>Suche: </b>Beraterprofil</div>
                                    
                    <div className="fast-search">
                        <button className={"micro"+((searchAttr === 'kuerzel' && searchVal === loggedInUsername) ? ' clicked' : '')} onClick={() => {setSearchAttr('kuerzel'); setSearchVal(loggedInUsername); onFilter('kuerzel', loggedInUsername)}}>Nur eigene</button> 
                    </div>
                    <div className="checkbox-label"><input name="former" type="checkbox" checked={(searchEndDate === new Date().toISOString().slice(0, 10))} onChange={e => {(searchEndDate === new Date().toISOString().slice(0, 10) ? setSearchEndDate(-1) : setSearchEndDate(new Date().toISOString().slice(0, 10)))}} />Ehemalige</div>
                    <div className="fast-search">
                        <button className={"micro"+((onlyAvailableConsultant) ? ' clicked' : '')} onClick={() => {setOnlyAvailableConsultant(!onlyAvailableConsultant); onFilter(null,null,null,!onlyAvailableConsultant)}}>Nur Verfügbare</button> 
                    </div>

                    {/* <div className="date-label">
                        Berater: 
                        <DateSearch searchStartDate={searchStartDate} searchEndDate={searchEndDate} setSearchStartDate={setSearchStartDate} setSearchEndDate={setSearchEndDate} />
                    </div>
                    <div className="date-label">
                        Beraterprojekt:
                        <DateSearch searchStartDate={searchStartDate} searchEndDate={searchEndDate} setSearchStartDate={setSearchStartDate} setSearchEndDate={setSearchEndDate} />
                    </div> */}

                    <div className="extend-search">
                        Erfahrung: <input className="search-input" type="number" name="SearchVal2" value={searchVal2} onChange={e=> setSearchVal2(e.target.value)} />
                    </div>

                    <div className="search-bar">
                        <Select className="dropdown" classNamePrefix="react-select" name="SearchAttr" options={options} value={optionsFilter(options, 'value', searchAttr)} isSearchable={false} defaultValue={options[0]} placeholder={(readOnly ? "" : "Bitte wählen...")} onChange={s => setSearchAttr(s.value)}/>
                        {searchAttr==='kompetenzen' && <Select className="dropdown" classNamePrefix="react-select" name="SearchFKAttr" options={competenceFilterData} placeholder={(readOnly ? "" : "Bitte wählen...")} isSearchable={false}  onChange={s => setSearchFKAttr(s.value)}/> }
                        <input className="search-input" type="text" name="SearchVal" value={searchVal} onChange={e=> setSearchVal(e.target.value)} onKeyDown={(event) => {if(event.key == 'Enter'){onFilter()}}} />
                    </div>
                </div>
                
                <HeaderButtons main={{function: onFilter, value: 'Filter anwenden'}} second={{function: onFilterReset, value: 'Filter zurücksetzen'}} />
            </div>
        )

    } else {
        return(
            <div className="content-header" id="print">
                <Tabs tabs={tabs} activeTab={tab} setTab={setTab} />
                <div className="sum-attributes">
                    {Object.keys(businessobject_data.berater.sumAttributes).map((attr, index) => (<div key={index} className="label">{businessobject_data.berater.sumAttributes[attr]}<input disabled value={object ? (attr.includes("_") ? (object[attr.substring(0,attr.indexOf("_"))]+', '+object[attr.substring(attr.indexOf("_")+1)])  : object[attr]) : ''} /></div>))}
                </div>
                <div className="view-select">
                    <SingleSelect filterState={profileView} setFilterState={setProfileView} selection={{intern: 'Intern', extern: 'Extern'}} readOnly={readOnly} notNull={true} />
                    <SingleSelect filterState={consultantView} setFilterState={setConsultantView} selection={{FACHLICH: 'Fachlich', TECHNISCH: 'Technisch'}} readOnly={readOnly} />
                </div>
                
                <HeaderButtons main={{function:onCreate, value: 'Erstellen'}} disabled={(readOnly || tab !== tabs[tabs.length-1])} smalls={[{function:() => history.push('/drucken'), value: 'Zurück', disabled: 'never'}]} />
            </div>
        )
    }
}

export default DruckenKopfzeile
