import React from 'react'
import data from './../../data/_data';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faGlobe, faLock, faPen, faPlus, faUserShield } from '@fortawesome/free-solid-svg-icons';
import {TooltipInfo} from './InnerContent';


function StatusCycle({status, isPM, role, accessStatus, businessObject, setStatus, readOnly}) {
    if (accessStatus === data.accessStatus.create){
        status = data.accessStatus.create
        readOnly = true
    }
    if (!(typeof setStatus == 'function') || status === data.status.GESPERRT || businessObject === data.businessObject.gefuehrt || businessObject === data.businessObject.moderation){
        readOnly = true
    }

    const changeStatus = (status) => {
        if (setStatus){
            setStatus(status)
        }
    }

    return(
        <div className='status-cycle'>
            {accessStatus === data.accessStatus.create && 
                <div className="inner-contents">
                    <div 
                        className='status-icon-label' 
                        style={{
                            color: (status === data.accessStatus.create ? '#BAC109' : 'inherit')
                        }}
                        onClick={null} // Kein `onClick`, daher deaktiviert
                    >
                        <FontAwesomeIcon className="status-icon" size="1x" icon={faPlus} />
                        Anlegen
                        <TooltipInfo name='anlegen' />
                    </div>
                    <span className="status-cycle-divide"></span>
                </div>
            }
            <div 
                className='status-icon-label' 
                onClick={accessStatus === data.accessStatus.create ? null : () => {changeStatus(data.status.GESPERRT)}} // Dynamisch deaktiviert
                style={{
                    color: (status === data.status.GESPERRT ? '#DC7E63' : 'inherit'),
                    cursor: accessStatus === data.accessStatus.create ? 'not-allowed' : 'pointer' // Visuelles Feedback
                }}
            >
                <FontAwesomeIcon className="status-icon" size="1x" icon={faLock} />
                {data.presentation.status.GESPERRT}
                <TooltipInfo name={data.status.GESPERRT} />
            </div>
            {(((isPM || (role === data.roles.BECKOFFICE || role === data.roles.QUALITAETSSICHERUNG)) &&
            (businessObject === data.businessObject.consultant || 
            businessObject === data.businessObject.consultantproject || 
            businessObject === data.businessObject.gefuehrt || 
            businessObject === data.businessObject.moderation))) ? (
                <div className="inner-contents">
                    <div className='status-cycle-divider-stack'>
                        <span className="status-cycle-divide pos"></span>
                        <span className="status-cycle-divide neg"></span>
                    </div>
                    <div className='status-cycle-stack'>
                        <div 
                            className='status-icon-label' 
                            onClick={((readOnly && !isPM) || accessStatus === data.accessStatus.custom) ? null : () => {changeStatus(data.status.BEARBEITUNG)}} 
                            style={{
                                color: (status === data.status.BEARBEITUNG ? '#F0D490' : 'inherit'),
                                cursor: ((readOnly && !isPM) || accessStatus === data.accessStatus.custom) ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <FontAwesomeIcon className="status-icon" size="1x" icon={faPen} />
                            {data.presentation.status.BEARBEITUNG}
                            <TooltipInfo name={data.status.BEARBEITUNG} />
                        </div>
                        <div 
                            className='status-icon-label' 
                            onClick={((readOnly && !isPM) || accessStatus === data.accessStatus.custom) ? null : () => {changeStatus(data.status.FREIGABE_MITARBEITER)}} 
                            style={{
                                color: (status === data.status.FREIGABE_MITARBEITER ? '#DEAB00' : 'inherit'),
                                cursor: ((readOnly && !isPM) || accessStatus === data.accessStatus.custom) ? 'not-allowed' : 'pointer'
                            }}
                        >
                            <FontAwesomeIcon className="status-icon" size="1x" icon={faUserShield} />
                            {data.presentation.status.FREIGABE_MITARBEITER}
                            <TooltipInfo name={data.status.FREIGABE_MITARBEITER} />
                        </div>
                    </div>
                    <div className='status-cycle-divider-stack'>
                        <span className="status-cycle-divide neg"></span>
                        <span className="status-cycle-divide pos"></span>
                    </div>
                </div>
            ) : <span className="status-cycle-divide"></span>}
            <div 
                className='status-icon-label' 
                onClick={(accessStatus === data.accessStatus.create || status === data.status.GESPERRT || accessStatus === data.accessStatus.custom) ? null : () => {changeStatus(data.status.FREIGABE_QUALITAETSSICHERUNG)}} 
                style={{
                    color: (status === data.status.FREIGABE_QUALITAETSSICHERUNG ? '#BAC109' : 'inherit'),
                    cursor: (accessStatus === data.accessStatus.create || status === data.status.GESPERRT || accessStatus === data.accessStatus.custom) ? 'not-allowed' : 'pointer'
                }}
            >
                <FontAwesomeIcon className="status-icon" size="1x" icon={faGlobe} />
                {data.presentation.status.FREIGABE_QUALITAETSSICHERUNG}
                <TooltipInfo name={data.status.FREIGABE_QUALITAETSSICHERUNG} />
            </div>
        </div>
    )
}
export default StatusCycle