import data from '../../data/_data'

/**
 * wird zur Prüfung von ReadOnly verwendet
 * Überprüft ob der aktuell angemeldete Benutzer, berechtigt ist das Geschäftsobjekt zu bearbeiten oder nur zu Lesen
 * 
 * @author DHR
 * @param {String} role - aktuelle Rolle des angemeldeten Benutzers
 * @param {Strig} username - aktueller Benutzername des angemeldeten Benutzers
 * @param {int} id - aktuelle ID des ausgewählte Geschäftsobjektes
 * @param {String} businessObject - aktuelles Geschäftsobjekt der Route
 * @param {Object} [object = {}] - Geschäftsobjekt
 * @returns {boolean} ob ReadOnly
 */
export function PermissionForbidden (role, username, id, businessObject, object = {}){
    if (businessObject === data.businessObject.user || businessObject === data.businessObject.layout && role === data.roles.ADMIN){return false}
    else if (role === data.roles.BECKOFFICE){return false}
    else if (id === username && businessObject === data.businessObject.consultant){return false}
    else if (id === 'neu' && businessObject===data.businessObject.consultantproject && role !== data.roles.ADMIN){return false}
    else if (id === 'neu' && businessObject===data.businessObject.competence && role === data.roles.QUALITAETSSICHERUNG){return false}
    else if (((businessObject === data.businessObject.consultant && id !== 'neu' && object && object.kuerzel === username) || (businessObject === data.businessObject.consultantproject && id !== 'neu' && object && object.berater === username)) && (object.status === data.status.BEARBEITUNG || object.status === data.status.FREIGABE_QUALITAETSSICHERUNG)){return false}
    else if (object && object.status === data.status.FREIGABE_MITARBEITER && role === data.roles.QUALITAETSSICHERUNG){return false}
    else{return true}
}